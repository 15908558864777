// Import our CSS
import styles from '../css/app.pcss';

// Vendors
// import hoverEffect from 'hover-effect';
window.$ = window.jQuery = require('jquery');
import hoverEffect from './image-transition.js';
import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
require('./detect-element.js');

const swup = new Swup({
  plugins: [
    new SwupScrollPlugin({
      doScrollingRightAway: false,
      animateScroll: false,
    }),
    new SwupScriptsPlugin({
      head: false,
      body: true,
    }),
  ],
});
let currentColour = '';
let newColour = '';
let scrolled = false;

// App main
const main = async () => {
  // reset nav
  $('nav')
    .addClass('slide-up')
    .removeClass('slide-down');

  // Home page add margin to nav
  if (location.pathname === '/') {
    $('nav').addClass('mt-0 md:mt-100-vh');
  } else {
    $('nav').removeClass('mt-0 md:mt-100-vh');
  }

  // Make all links with external class open in new tab
  $('.external').attr('target', '_blank');

  // Highlight links
  $('a').removeClass('active');
  $('a[href="' + location.pathname + '"]').addClass('active');

  // Initial page load
  window.onload = new (function() {
    setTimeout(() => {
      $('body')
        .removeClass('opacity-0')
        .addClass('show');
    }, 500);
  })();

  $(window).mousemove(function(event) {
    $('.cursor')
      .addClass('show')
      .removeClass('hide')
      .css({
        'top': event.pageY + 'px',
        'left': event.pageX + 'px',
      });
  });

  // Section changes colour and toggles nav on scroll up
  let lastScrollTop = 0;

  window.addEventListener('scroll', function(e) {
    $('.cursor')
      .addClass('hide')
      .removeClass('show');
    let st = $(window).scrollTop();
    let menu = !$('#menuToggle input').is(':checked');

    if (st > lastScrollTop && menu) {
      // Scrolling Down
      $('nav')
        .addClass('slide-up')
        .removeClass('slide-down');
    } else if (menu) {
      // Scrolling Up
      $('nav')
        .addClass('slide-down')
        .removeClass('slide-up');
    }
    lastScrollTop = st;
  });

  // Change Colour init on loading hidden

  var observer = new IntersectionObserver(
    function(entries) {
      if (entries[0].isIntersecting === true) {
        newColour = entries[0].target.dataset.changeTo;

        if (scrolled) {
          updateColour();
        }
      }
    },
    {
      threshold: [0.3],
    }
  );

  function updateColour() {
    $('#swup').removeClass(currentColour);
    $('body').removeClass(currentColour);

    $('body').addClass(newColour);
    $('#swup').addClass(newColour);

    if (newColour === 'bg-gold' || newColour === 'bg-earth') {
      $('#swup').addClass('desaturate-images');
    } else {
      $('#swup').removeClass('desaturate-images');
    }

    currentColour = newColour;
  }

  if (!scrolled) {
    $(window).on('scroll', function() {
      if (window.scrollY > 216) {
        updateColour();
        scrolled = true;
        $(window).off('scroll');
      }
    });
  }

  if ($('#loading').hasClass('hide')) {
    setTimeout(() => {
      let sections = document.querySelectorAll('.changes-colour');
      sections.forEach((section) => {
        observer.observe(section);
      });
    }, 500);
  }

  // Init image header hover
  if ($('.hover-image').length) {
    var oneToTwo = new hoverEffect({
      parent: document.querySelector('.oneToTwo'),
      intensity: 0.02,
      image1: document.getElementById('image1').dataset.src,
      image2: document.getElementById('image2').dataset.src,
      displacementImage: 'assets/static/displacement.png',
    });
    var twoToThree = new hoverEffect({
      parent: document.querySelector('.twoToThree'),
      intensity: 0.02,
      image1: document.getElementById('image2').dataset.src,
      image2: document.getElementById('image3').dataset.src,
      displacementImage: 'assets/static/displacement.png',
    });
    var threeToFour = new hoverEffect({
      parent: document.querySelector('.threeToFour'),
      intensity: 0.02,
      image1: document.getElementById('image3').dataset.src,
      image2: document.getElementById('image4').dataset.src,
      displacementImage: 'assets/static/displacement.png',
    });
    // please respect authorship and do not remove
    console.log(
      '%cHover effect by Robin Delaporte: https://github.com/robin-dela/hover-effect ',
      'color: #bada55; font-size: 0.7rem'
    );
  }
  let mouseIn = -1;

  // Trigger hover image effect
  $('.image-header .image.1').hover(function() {
    if (mouseIn == 1) {
      // do nothing
      oneToTwo.previous();
    } else {
      // coming from 2
      $('.image-sets').addClass('invisible');
      $('.oneToTwo').removeClass('invisible');
      oneToTwo.previous();
    }
    mouseIn = 1;
  }),
    this;

  $('.image-header .image.2').hover(function() {
    if (mouseIn == 1) {
      // coming from one
      oneToTwo.next();
      // $('.image-sets').addClass('invisible');
      // $('.oneToTwo').removeClass('invisible');
    } else {
      // coming from 3
      $('.image-sets').addClass('invisible');
      $('.twoToThree').removeClass('invisible');
      twoToThree.previous();
    }
    mouseIn = 2;
  }),
    this;

  $('.image-header .image.3').hover(function() {
    if (mouseIn == 2) {
      // coming from two

      $('.image-sets').addClass('invisible');
      $('.twoToThree').removeClass('invisible');
      twoToThree.next();
    } else {
      // coming from 4
      $('.image-sets').addClass('invisible');
      $('.threeToFour').removeClass('invisible');
    }
    mouseIn = 3;
  }),
    this;

  $('.image-header .image.4').hover(function() {
    if (mouseIn == 3) {
      // coming from three
      $('.image-sets').addClass('invisible');
      $('.threeToFour').removeClass('invisible');
      threeToFour.next();
    } else {
      // coming from 3
      $('.image-sets').addClass('invisible');
      $('.threeToFour').removeClass('invisible');
      threeToFour.previous();
    }
    mouseIn = 4;
  }),
    this;

  // Loading
  $('#loading').on('click', function() {
    $('body').removeClass('overflow-hidden');
    $('.page-content').removeClass('hidden');
    $('#loading')
      .removeClass('show')
      .toggleClass('hide');

    setTimeout(() => {
      $('#loading').css('visibility', 'hidden');
      let sections = document.querySelectorAll('.changes-colour');
      sections.forEach((section) => {
        observer.observe(section);
      });
    }, 500);
  });

  $('.home-arrow').on('click', function() {
    $('html, body')
      .stop()
      .animate(
        {
          scrollTop: window.innerHeight,
        },
        800
      );
  });

  // Reveal
  $('.reveal').on('click', function() {
    // User is clicking on one that is already open ?
    if (
      $(this)
        .find('.open-icon')
        .hasClass('rotate')
    ) {
      $('.reveal .open-icon').removeClass('rotate');
      $('.reveal-more').removeClass('open');
      $('.neg-mobile-margin').removeClass('up');
      $('.reveal')
        .prev()
        .css('filter', 'saturate(0.1)');
    } else {
      // Close any
      $('.reveal .open-icon').removeClass('rotate');
      $('.reveal-more').removeClass('open');
      $('.neg-mobile-margin').removeClass('up');
      $('.reveal')
        .prev()
        .css('filter', 'saturate(0.1)');
      // Now open
      $(this)
        .find('.open-icon')
        .toggleClass('rotate');
      $(this)
        .next()
        .toggleClass('open');
      $(this)
        .prev()
        .css('filter', 'saturate(1)');
      $('.neg-mobile-margin').addClass('up');
    }
  });

  // Video
  $('.play').on('click', function() {
    $(this)
      .prev()
      .attr('controls', 'true');
    $(this)
      .prev()
      .trigger('play');

    $(this).addClass('hide');
    setTimeout(() => {
      $(this).remove();
    }, 500);
  });

  // Form fields
  $('input.form-input').blur(function() {
    if (!$(this).val()) {
      $(this).removeClass('is-active');
    }
  });

  $('input').focus(function() {
    $(this).addClass('is-active');
  });

  // Dont show the splash page on success page
  if (location.pathname === '/success') {
    $('#loading')[0].click();
  }
};

// Execute async function
main().then((vm) => {});

swup.on('clickLink', (e) => {
  $('.nav-item a').removeClass('active');

  $(e.delegateTarget).each(function(index) {
    $(this).addClass('active');
  });

  $('#menuToggle input').prop('checked', false);
  $('#swup').removeClass(currentColour, 'desaturate-images');
  $('body').removeClass(currentColour);
  currentColour = '';
  newColour = '';
  scrolled = false;
});

swup.on('contentReplaced', main);

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
